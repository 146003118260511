import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';

export function TextDialog({showRef, children}){

    const [shown, setShown] = useState(false);

    showRef.current = () => setShown(true);

    return (
        <Modal
        show={shown}
        backdrop="static"
        keyboard={false}
        animation={true}
        size="xl">

        <Modal.Body className='modal-body'>
            {children}
        </Modal.Body>
        <Modal.Footer>
           <Button variant="secondary" onClick={() => {
                 setShown(false);
           }}>
                 OK
           </Button>
        </Modal.Footer>
     </Modal>
    )
}
