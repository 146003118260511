"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseAddressWithPort = exports.createServerFromDotRdpFile = void 0;

var _Constants = require("./Constants");

var _Server = require("./Server");

var parseAddressWithPort = function parseAddressWithPort(addressWithPortUntrimmed) {
  var addressWithPort = addressWithPortUntrimmed.trim();
  var firstClosingSquareBracket = addressWithPort.indexOf(']');

  if (firstClosingSquareBracket >= 0) {
    //IpV6, possibly with port
    var address = addressWithPort.substring(1, firstClosingSquareBracket);
    var colonAfterBracket = addressWithPort.substring(firstClosingSquareBracket).indexOf(':');
    var port;

    if (colonAfterBracket >= 0) {
      port = addressWithPort.substring(firstClosingSquareBracket + colonAfterBracket + 1);
    }

    return {
      address: address,
      port: port
    };
  } else {
    var firstSemicolon = addressWithPort.indexOf(':');

    if (firstSemicolon >= 0) {
      var secondSemicolon = addressWithPort.substring(firstSemicolon + 1).indexOf(':');

      if (secondSemicolon >= 0) {
        //IPv6, without port
        return {
          address: addressWithPort
        };
      } //IPv4 or DNS hostname with port


      var _address = addressWithPort.substring(0, firstSemicolon);

      var _port = addressWithPort.substring(firstSemicolon + 1);

      return {
        address: _address,
        port: _port
      };
    } //IPv4 or DNS hostname without port


    return {
      address: addressWithPort
    };
  }
};

exports.parseAddressWithPort = parseAddressWithPort;

var parseDotRdpFile = function parseDotRdpFile(rdpfileContent) {
  var rdpProperties = {};
  var lines = rdpfileContent.match(/[^\r\n]+/g);
  lines.forEach(function (line) {
    line = line.trim();
    var firstColon = line.indexOf(":");

    if (firstColon < 0) {
      throw new Error('Incorrect line format: ' + line);
    } //convert name to lower case and replace spaces with underscores


    var name = line.substring(0, firstColon).toLowerCase().split(' ').join('_');
    var secondColon = line.indexOf(":", firstColon + 1);

    if (firstColon < 0) {
      throw new Error('Incorrect line format: ' + line);
    }

    var type = line.substring(firstColon + 1, secondColon);
    var value = line.substring(secondColon + 1);

    if (type === "i") {
      rdpProperties[name] = parseInt(value);
    } else {
      rdpProperties[name] = value;
    }
  });
  return rdpProperties;
};

var isIPv6Address = function isIPv6Address(address) {
  //IPv6 addresses have at least two semicolons
  var firstSemicolon = address.indexOf(':');

  if (firstSemicolon >= 0) {
    var secondSemicolon = address.substring(firstSemicolon + 1).indexOf(':');

    if (secondSemicolon >= 0) {
      return true;
    }
  }

  return false;
};

var formatAddressWithPort = function formatAddressWithPort(address, port) {
  if (isIPv6Address(address)) {
    return '[' + address + ']:' + port;
  } else {
    return address + ':' + port;
  }
};

var createServerFromDotRdpFile = function createServerFromDotRdpFile(rdpfileContent) {
  var rdpProperties = parseDotRdpFile(rdpfileContent);
  var server = new _Server.Server();

  if (!rdpProperties.full_address) {
    throw new Error('ERROR_INCORRECT_DOT_RDP_FULL_ADDRESS_REQUIRED');
  }

  if (typeof rdpProperties.server_port === "undefined") {
    server.address = rdpProperties.full_address;
  } else {
    var _parseAddressWithPort = parseAddressWithPort(rdpProperties.full_address),
        address = _parseAddressWithPort.address;

    server.address = formatAddressWithPort(address, rdpProperties.server_port);
  }

  if (rdpProperties.username) {
    server.username = rdpProperties.username;
  }

  if (rdpProperties.domain) {
    server.domain = rdpProperties.domain;
  }

  if ("desktopwidth" in rdpProperties) {
    server.desktopwidth = rdpProperties.desktopwidth;
  }

  if ("desktopheight" in rdpProperties) {
    server.desktopheight = rdpProperties.desktopheight;
  }

  if ("screen_mode_id" in rdpProperties) {
    if (rdpProperties.screen_mode_id === _Constants.ScreenMode.FULL_SCREEN) {
      server.fullscreen = true;
      server.fittoclientscreen = true;
    }
  }

  if ("use_multimon" in rdpProperties) {
    if (rdpProperties.use_multimon === 1) {
      server.multimon = true;
    }
  }

  if ("session_bpp" in rdpProperties) {
    switch (rdpProperties.session_bpp) {
      case 8:
      case 15:
      case 16:
      case 24:
      case 32:
        break;

      default:
        throw new Error('ERROR_INCORRECT_DOT_RDP_UNSUPPORTED_SESSION_BPP');
    }

    server.colordepth = rdpProperties.session_bpp;
  }

  if ("audiomode" in rdpProperties) {
    switch (rdpProperties.audiomode) {
      case _Constants.AudioMode.REDIRECT:
      case _Constants.AudioMode.PLAY_ON_SERVER:
      case _Constants.AudioMode.AUDIO_MODE.NONE:
        break;

      default:
        throw new Error('ERROR_INCORRECT_DOT_RDP_UNSUPPORTED_AUDIOMODE');
    }

    server.audioredirect = rdpProperties.audiomode;
  }

  if ("administrative_session" in rdpProperties) {
    server.connecttoconsole = 0 != rdpProperties.administrative_session;
  } else if ("connect_to_console" in rdpProperties) {
    server.connecttoconsole = 0 != rdpProperties.connect_to_console;
  } //SSTODO - add support for the compression setting on the Advanced tab and in the plugin


  if ("compression" in rdpProperties) {
    server.compression = 0 != rdpProperties.compression;
  }

  if ("disable_full_window_drag" in rdpProperties) {
    server.allowfullwindowdrag = 0 === rdpProperties.disable_full_window_drag;
  }

  if ("disable_menu_anims" in rdpProperties) {
    server.allowmenuanims = 0 === rdpProperties.disable_menu_anims;
  }

  if ("disable_themes" in rdpProperties) {
    server.allowthemes = 0 === rdpProperties.disable_themes;
  }

  if ("disable_wallpaper" in rdpProperties) {
    server.enablewallpaper = 0 === rdpProperties.disable_wallpaper;
  }

  if ("allow_font_smoothing" in rdpProperties) {
    server.allowfontsmoothing = 0 != rdpProperties.allow_font_smoothing;
  }

  if ("allow_desktop_composition" in rdpProperties) {
    server.allowdesktopcomposition = 0 != rdpProperties.allow_desktop_composition;
  }

  if ("redirectclipboard" in rdpProperties) {
    server.clipboardredirect = 0 != rdpProperties.redirectclipboard;
  }

  if ("drivestoredirect" in rdpProperties) {
    server.storageredirect = rdpProperties.drivestoredirect.length > 0;
  }

  if ("redirectsmartcards" in rdpProperties) {
    server.smartcardredirect = 0 != rdpProperties.redirectsmartcards;
  }

  if ("connection_type" in rdpProperties) {
    switch (rdpProperties.connection_type) {
      case _Constants.ConnectionType.MODEM:
      case _Constants.ConnectionType.BROADBAND_LOW:
      case _Constants.ConnectionType.SATELLITE:
      case _Constants.ConnectionType.BROADBAND_HIGH:
      case _Constants.ConnectionType.WAN:
      case _Constants.ConnectionType.LAN:
      case _Constants.ConnectionType.AUTODETECT:
        break;

      default:
        throw new Error('ERROR_INCORRECT_DOT_RDP_UNSUPPORTED_CONNECTION_TYPE');
    }

    server.connectiontype = rdpProperties.connection_type;
  }

  if (rdpProperties.alternate_shell) {
    server.programtostart = rdpProperties.alternate_shell;
  }

  if (rdpProperties.shell_working_directory) {
    server.startinfolder = rdpProperties.shell_working_directory;
    server.remoteappprogramworkfolder = rdpProperties.shell_working_directory;
  }

  if ("remoteapplicationmode" in rdpProperties) {
    server.remoteapp = 0 != rdpProperties.remoteapplicationmode;
  }

  if (rdpProperties.remoteapplicationprogram) {
    server.remoteappprogram = rdpProperties.remoteapplicationprogram;
  }

  if (rdpProperties.remoteapplicationcmdline) {
    server.remoteappprogramargs = rdpProperties.remoteapplicationcmdline;
  }

  if ("gatewayusagemethod" in rdpProperties) {
    switch (rdpProperties.gatewayusagemethod) {
      case _Constants.TscProxyNode.DIRECT:
        server.use_gateway = true;
        server.bypassgatewayforlocal = false;
        break;

      case _Constants.TscProxyNode.DETECT:
        server.use_gateway = true;
        server.bypassgatewayforlocal = true;
        break;

      case _Constants.TscProxyNode.NONE_DIRECT:
      case _Constants.TscProxyNode.NONE_DETECT:
      case _Constants.TscProxyNode.DEFAULT:
      default:
        server.use_gateway = false;
    }
  }

  if (rdpProperties.gatewayhostname) {
    server.gateway_address = rdpProperties.gatewayhostname;
  }

  if ("promptcredentialonce" in rdpProperties) {
    server.gateway_use_server_account = 0 != rdpProperties.promptcredentialonce;
  }

  if (rdpProperties.loadbalanceinfo) {
    server.loadbalanceinfo = rdpProperties.loadbalanceinfo;
  }

  if ("authentication_level" in rdpProperties) {
    switch (rdpProperties.authentication_level) {
      case AuthrnticationLevelL.SERVER_AUTH_FAILS_CONNECT_WITHOUT_WARNING:
        server.ingnorecertificate = true;
        break;

      case AuthrnticationLevelL.SERVER_AUTH_FAILS_DO_NOT_CONNECT:
      case AuthrnticationLevelL.SERVER_AUTH_FAILS_SHOW_WARNING:
      case AuthrnticationLevelL.NOT_SPECIFIED:
      default:
        server.ingnorecertificate = false;
    }
  }

  if ("autoreconnection_enabled" in rdpProperties) {
    server.autoreconnection_enabled = 0 != rdpProperties.autoreconnection_enabled;
  }

  if ("autoreconnect_max_retries" in rdpProperties) {
    if (isNaN(rdpProperties.autoreconnect_max_retries) || rdpProperties.autoreconnect_max_retries < 1) {
      throw new Error('ERROR_INCORRECT_DOT_RDP_UNSUPPORTED_AUTORECONNECT_MAX_RETRIES');
    }

    server.autoreconnect_max_retries = rdpProperties.autoreconnect_max_retries;
  }

  return server;
};

exports.createServerFromDotRdpFile = createServerFromDotRdpFile;