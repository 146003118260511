import React, { useState, useRef } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { DBService } from "../services/DBService";
import { Session } from "react-rdp-client-lib";
import classNames from "classnames";
import 'fontsource-roboto';
import { Home } from './Home'
import { Edit } from './Edit'


 function App(props) {

  const [inSession, setInSession] = useState(false);
  const sortAscendingState = useState(true);

  //Will be set when session starts to point to disconnect method inside the session
  const disconnect = useRef(null);
  const dbService =  useRef(new DBService());

  const disconnectSession = () => {
    disconnect.current();
  };

  const onSessionStarted = () => {
    setInSession(true);
  }

  const onSessionStopped = () => {
    setInSession(false);
  }

  let appClass = classNames({
    'embedded': true,
    'fullscreen': false,
    'connected': inSession,
    'mx-auto': true
  });

    return (
      <div id="app" className={appClass}>
        <Routes basename={ process.env.PUBLIC_URL }>
          <Route path="/" element = {<Home version={props.version} dbService={dbService.current} sortAscendingState={sortAscendingState}/> }/>
          <Route path="/edit" element = {<Edit dbService={dbService.current}/>}/>
          <Route path='/session' element={<Session
            onStart={onSessionStarted}
            onStop={onSessionStopped}
            setDisconnect={(value)=>disconnect.current = value}
            shrinkToFit={true}
            saveServer={(unused, server) => dbService.current.saveServer({...server})}
            rdp_client_wasm_module_promise={props.rdp_client_wasm_module_promise}/>}
          />
        </Routes>
      </div>
    );
  }

export default App