import { openDB } from 'idb';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const DATABASE_NAME = 'gen_xtralogic_rd_db';
const CURR_DB_VERSION =1;
const SERVER_OBJECT_STORE_NAME = "server";

function encrypt(decrypted, key)
{
   var encrypted = AES.encrypt(decrypted, key).toString();

   return encrypted;
}

function decrypt(encrypted, key)
{
   var decrypted = AES.decrypt(encrypted, key).toString(Utf8);

   return decrypted;
}

const decryptServerFields = (server) => {
    if ("password" in server) {
        //server.password = decrypt(server.password, server.id.toString());
    }

    if ("gateway_password" in server) {
        //server.gateway_password = decrypt(server.gateway_password, server.id.toString());
    }
}

const dbPromise = openDB(DATABASE_NAME, CURR_DB_VERSION,   {
    upgrade(db, oldVersion) {
        switch (oldVersion) {
            case 0:
               db.createObjectStore(SERVER_OBJECT_STORE_NAME, { keyPath: "id", autoIncrement:true });
            //eslint-disable-next-line no-fallthrough
            case 1: //stub for the next version
            break;
            default:
               console.log("idb: Unexpected oldVersion in upgrade: " + oldVersion);
        }
    },
    blocked() {
        console.log("idb: There are older versions of the database open on the origin, so this version cannot open.")
    },
    blocking() {
        console.log("idb: This connection is blocking a future version of the database from opening.")
    },
    terminated() {
        console.log("idb: the browser abnormally terminated the connection.")
    },
});

export class DBService {
    getObject(objectStore, key) {
        return dbPromise.then(db => {
          return db.transaction(objectStore, 'readonly').objectStore(objectStore).get(key);
        });
    }

    getAllObjects(objectStore) {
        return dbPromise.then(db => {
            return db.transaction(objectStore, 'readonly').objectStore(objectStore).getAll();
        });
    }

    saveObject(objectStore, object) {
        return dbPromise.then(db => {
            return db.transaction(objectStore, 'readwrite').objectStore(objectStore).put(object);
        });
    }

    deleteObject(objectStore, key) {
        return dbPromise.then(db => {
            return db.transaction(objectStore, 'readwrite').objectStore(objectStore).delete(key);
        });
    }

    deleteAllObjects(objectStore) {
        return dbPromise.then(db => {
            return db.transaction(objectStore, 'readwrite').objectStore(objectStore).clear();
        });
    }

   async isObjectStoreEmpty(objectStore){
        let db = await dbPromise;
        return db.transaction(objectStore, 'readonly').objectStore(objectStore).openCursor() == null;
    }

    getServer(id) {
        return this.getObject(SERVER_OBJECT_STORE_NAME, id).then(server => {
            decryptServerFields(server);
            return Promise.resolve(server);
        });
    }

    async getAllServers() {
        const servers = await this.getAllObjects(SERVER_OBJECT_STORE_NAME);

        servers.forEach(server => decryptServerFields(server));

        return servers;
    }

    saveServer(server) {
        if ("password" in server) {
            //server.password = encrypt(server.password, server.id ? server.id.toString() : '1');
        }

        if ("gateway_password" in server) {
            //server.gateway_password = encrypt(server.gateway_password, server.id ? server.toString() : '1');
        }

        return this.saveObject(SERVER_OBJECT_STORE_NAME, server);
    }

    deleteServer(id) {
        return this.deleteObject(SERVER_OBJECT_STORE_NAME, id);
    }

    deleteAllServers() {
        return this.deleteAllObjects(SERVER_OBJECT_STORE_NAME);
    }
}