import './trusted-security-policies.js'; //must be the first import
import React from "react"
import { createRoot } from 'react-dom/client';
import { MemoryRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./components/App"
import "./css/App.css"

let version = '0.0.1';

let rdp_client_wasm_module_promise = import("./wasm-rdp-client-pkg").then(module => {
  module.init();
  return module;
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(process.env.PUBLIC_URL + '/service-worker.js')
    .then(reg => console.log('Registered service worker, scope: ' + reg.scope)).catch(function (e)
    {
      console.error('Error during service worker registration:', e);
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  //<React.StrictMode>
  <MemoryRouter >
    <App version={version} rdp_client_wasm_module_promise={rdp_client_wasm_module_promise}/>
  </MemoryRouter>
  //</React.StrictMode>
);