"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TscProxyNode = exports.TransportType = exports.SecurityOptions = exports.ScreenMode = exports.ProtocolType = exports.Languages = exports.LanguageOptions = exports.Constants = exports.ConnectionType = exports.AuthrnticationLevel = exports.AudioMode = void 0;
var ProtocolType = Object.freeze({
  PROTOCOL_RDP: 0x00000000,
  //Standard RDP Security (section 5.3). (legacy)
  PROTOCOL_SSL: 0x00000001,
  //TLS 1.0, 1.1, or 1.2 (section 5.4.5.1).
  PROTOCOL_HYBRID: 0x00000002,
  //Credential Security Support Provider protocol (CredSSP) (section 5.4.5.2). If this flag is set, then the PROTOCOL_SSL (0x00000001) flag SHOULD also be set because Transport Layer Security (TLS) is a subset of CredSSP.
  PROTOCOL_RDSTLS: 0x00000004,
  //RDSTLS protocol (section 5.4.5.3).
  PROTOCOL_HYBRID_EX: 0x00000008 //Credential Security Support Provider protocol (CredSSP) (section 5.4.5.2) coupled with the Early User Authorization Result PDU (section 2.2.10.2). If this flag is set, then the PROTOCOL_HYBRID (0x00000002) flag SHOULD also be set. For more information on the sequencing of the CredSSP messages and the Early User Authorization Result PDU, see sections 5.4.2.1 and 5.4.2.2.

});
exports.ProtocolType = ProtocolType;
var TransportType = Object.freeze({
  DIRECT_SOCKET: 0,
  IAP: 1
});
exports.TransportType = TransportType;
var AudioMode = Object.freeze({
  REDIRECT: 0,
  PLAY_ON_SERVER: 1,
  NONE: 2
});
exports.AudioMode = AudioMode;
var ScreenMode = Object.freeze({
  FULL_SCREEN: 2
});
exports.ScreenMode = ScreenMode;
var ConnectionType = Object.freeze({
  MODEM: 0x01,
  BROADBAND_LOW: 0x02,
  SATELLITE: 0x03,
  BROADBAND_HIGH: 0x04,
  WAN: 0x05,
  LAN: 0x06,
  AUTODETECT: 0x07
});
exports.ConnectionType = ConnectionType;
var TscProxyNode = Object.freeze({
  NONE_DIRECT: 0x0,
  DIRECT: 0x1,
  DETECT: 0x2,
  DEFAULT: 0x3,
  NONE_DETECT: 0x4
});
exports.TscProxyNode = TscProxyNode;
var AuthrnticationLevel = Object.freeze({
  SERVER_AUTH_FAILS_CONNECT_WITHOUT_WARNING: 0,
  SERVER_AUTH_FAILS_DO_NOT_CONNECT: 1,
  SERVER_AUTH_FAILS_SHOW_WARNING: 2,
  NOT_SPECIFIED: 3
});
exports.AuthrnticationLevel = AuthrnticationLevel;
var Languages = Object.freeze({
  EN_US: 0x00000409,
  EN_US_DVORAK: 0x00010409
});
exports.Languages = Languages;
var LanguageOptions = [{
  value: 0x0436,
  text: "Afrikaans"
}, {
  value: 0x041c,
  text: "Albanian"
}, {
  value: 0x0401,
  text: "Arabic (Saudi Arabia)"
}, {
  value: 0x0801,
  text: "Arabic (Iraq)"
}, {
  value: 0x0c01,
  text: "Arabic (Egypt)"
}, {
  value: 0x1001,
  text: "Arabic (Libya)"
}, {
  value: 0x1401,
  text: "Arabic (Algeria)"
}, {
  value: 0x1801,
  text: "Arabic (Morocco)"
}, {
  value: 0x1c01,
  text: "Arabic (Tunisia)"
}, {
  value: 0x2001,
  text: "Arabic (Oman)"
}, {
  value: 0x2401,
  text: "Arabic (Yemen)"
}, {
  value: 0x2801,
  text: "Arabic (Syria)"
}, {
  value: 0x2c01,
  text: "Arabic (Jordan)"
}, {
  value: 0x3001,
  text: "Arabic (Lebanon)"
}, {
  value: 0x3401,
  text: "Arabic (Kuwait)"
}, {
  value: 0x3801,
  text: "Arabic (U.A.E.)"
}, {
  value: 0x3c01,
  text: "Arabic (Bahrain)"
}, {
  value: 0x4001,
  text: "Arabic (Qatar)"
}, {
  value: 0x042b,
  text: "Armenian"
}, {
  value: 0x044d,
  text: "Assamese"
}, {
  value: 0x042c,
  text: "Azeri (Latin)"
}, {
  value: 0x082c,
  text: "Azeri (Cyrillic)"
}, {
  value: 0x042d,
  text: "Basque"
}, {
  value: 0x0423,
  text: "Belarussian"
}, {
  value: 0x0445,
  text: "Bengali"
}, {
  value: 0x0402,
  text: "Bulgarian"
}, {
  value: 0x0455,
  text: "Burmese"
}, {
  value: 0x0403,
  text: "Catalan"
}, {
  value: 0x0404,
  text: "Chinese (Taiwan)"
}, {
  value: 0x0804,
  text: "Chinese (PRC)"
}, {
  value: 0x0c04,
  text: "Chinese (Hong Kong SAR, PRC)"
}, {
  value: 0x1004,
  text: "Chinese (Singapore)"
}, {
  value: 0x1404,
  text: "Chinese (Macau SAR)"
}, {
  value: 0x041a,
  text: "Croatian"
}, {
  value: 0x0405,
  text: "Czech"
}, {
  value: 0x0406,
  text: "Danish"
}, {
  value: 0x0413,
  text: "Dutch (Netherlands)"
}, {
  value: 0x0813,
  text: "Dutch (Belgium)"
}, {
  value: Languages.EN_US,
  text: "English (United States)"
}, {
  value: Languages.EN_US_DVORAK,
  text: "English (United States-Dvorak)"
}, {
  value: 0x0809,
  text: "English (United Kingdom)"
}, {
  value: 0x0c09,
  text: "English (Australian)"
}, {
  value: 0x1009,
  text: "English (Canadian)"
}, {
  value: 0x1409,
  text: "English (New Zealand)"
}, {
  value: 0x1809,
  text: "English (Ireland)"
}, {
  value: 0x1c09,
  text: "English (South Africa)"
}, {
  value: 0x2009,
  text: "English (Jamaica)"
}, {
  value: 0x2409,
  text: "English (Caribbean)"
}, {
  value: 0x2809,
  text: "English (Belize)"
}, {
  value: 0x2c09,
  text: "English (Trinidad)"
}, {
  value: 0x3009,
  text: "English (Zimbabwe)"
}, {
  value: 0x3409,
  text: "English (Philippines)"
}, {
  value: 0x0425,
  text: "Estonian"
}, {
  value: 0x0438,
  text: "Faeroese"
}, {
  value: 0x0429,
  text: "Farsi"
}, {
  value: 0x040b,
  text: "Finnish"
}, {
  value: 0x040c,
  text: "French (Standard)"
}, {
  value: 0x080c,
  text: "French (Belgian)"
}, {
  value: 0x0c0c,
  text: "French (Canadian)"
}, {
  value: 0x100c,
  text: "French (Switzerland)"
}, {
  value: 0x140c,
  text: "French (Luxembourg)"
}, {
  value: 0x180c,
  text: "French (Monaco)"
}, {
  value: 0x0437,
  text: "Georgian"
}, {
  value: 0x0407,
  text: "German (Standard)"
}, {
  value: 0x0807,
  text: "German (Switzerland)"
}, {
  value: 0x0c07,
  text: "German (Austria)"
}, {
  value: 0x1007,
  text: "German (Luxembourg)"
}, {
  value: 0x1407,
  text: "German (Liechtenstein)"
}, {
  value: 0x0408,
  text: "Greek"
}, {
  value: 0x0447,
  text: "Gujarati"
}, {
  value: 0x040d,
  text: "Hebrew"
}, {
  value: 0x0439,
  text: "Hindi"
}, {
  value: 0x040e,
  text: "Hungarian"
}, {
  value: 0x040f,
  text: "Icelandic"
}, {
  value: 0x0421,
  text: "Indonesian"
}, {
  value: 0x0410,
  text: "Italian (Standard)"
}, {
  value: 0x0810,
  text: "Italian (Switzerland)"
}, {
  value: 0x0411,
  text: "Japanese"
}, {
  value: 0x044b,
  text: "Kannada"
}, {
  value: 0x0860,
  text: "Kashmiri (India)"
}, {
  value: 0x043f,
  text: "Kazakh"
}, {
  value: 0x0457,
  text: "Konkani"
}, {
  value: 0x0412,
  text: "Korean"
}, {
  value: 0x0812,
  text: "Korean (Johab)"
}, {
  value: 0x0426,
  text: "Latvian"
}, {
  value: 0x0427,
  text: "Lithuanian"
}, {
  value: 0x0827,
  text: "Lithuanian (Classic)"
}, {
  value: 0x042f,
  text: "Macedonian"
}, {
  value: 0x043e,
  text: "Malay (Malaysian)"
}, {
  value: 0x083e,
  text: "Malay (Brunei Darussalam)"
}, {
  value: 0x044c,
  text: "Malayalam"
}, {
  value: 0x0458,
  text: "Manipuri"
}, {
  value: 0x044e,
  text: "Marathi"
}, {
  value: 0x0861,
  text: "Nepali (India)"
}, {
  value: 0x0414,
  text: "Norwegian (Bokmal)"
}, {
  value: 0x0814,
  text: "Norwegian (Nynorsk)"
}, {
  value: 0x0448,
  text: "Oriya"
}, {
  value: 0x0415,
  text: "Polish"
}, {
  value: 0x0416,
  text: "Portuguese (Brazil)"
}, {
  value: 0x0816,
  text: "Portuguese (Standard)"
}, {
  value: 0x0446,
  text: "Punjabi"
}, {
  value: 0x0418,
  text: "Romanian"
}, {
  value: 0x0419,
  text: "Russian"
}, {
  value: 0x044f,
  text: "Sanskrit"
}, {
  value: 0x0c1a,
  text: "Serbian (Cyrillic)"
}, {
  value: 0x081a,
  text: "Serbian (Latin)"
}, {
  value: 0x0459,
  text: "Sindhi"
}, {
  value: 0x041b,
  text: "Slovak"
}, {
  value: 0x0424,
  text: "Slovenian"
}, {
  value: 0x040a,
  text: "Spanish (Traditional Sort)"
}, {
  value: 0x080a,
  text: "Spanish (Mexican)"
}, {
  value: 0x0c0a,
  text: "Spanish (Modern Sort)"
}, {
  value: 0x100a,
  text: "Spanish (Guatemala)"
}, {
  value: 0x140a,
  text: "Spanish (Costa Rica)"
}, {
  value: 0x180a,
  text: "Spanish (Panama)"
}, {
  value: 0x1c0a,
  text: "Spanish (Dominican Republic)"
}, {
  value: 0x200a,
  text: "Spanish (Venezuela)"
}, {
  value: 0x240a,
  text: "Spanish (Colombia)"
}, {
  value: 0x280a,
  text: "Spanish (Peru)"
}, {
  value: 0x2c0a,
  text: "Spanish (Argentina)"
}, {
  value: 0x300a,
  text: "Spanish (Ecuador)"
}, {
  value: 0x340a,
  text: "Spanish (Chile)"
}, {
  value: 0x380a,
  text: "Spanish (Uruguay)"
}, {
  value: 0x3c0a,
  text: "Spanish (Paraguay)"
}, {
  value: 0x400a,
  text: "Spanish (Bolivia)"
}, {
  value: 0x440a,
  text: "Spanish (El Salvador)"
}, {
  value: 0x480a,
  text: "Spanish (Honduras)"
}, {
  value: 0x4c0a,
  text: "Spanish (Nicaragua)"
}, {
  value: 0x500a,
  text: "Spanish (Puerto Rico)"
}, {
  value: 0x0430,
  text: "Sutu"
}, {
  value: 0x0441,
  text: "Swahili (Kenya)"
}, {
  value: 0x041d,
  text: "Swedish"
}, {
  value: 0x081d,
  text: "Swedish (Finland)"
}, {
  value: 0x0449,
  text: "Tamil"
}, {
  value: 0x0444,
  text: "Tatar (Tatarstan)"
}, {
  value: 0x044a,
  text: "Telugu"
}, {
  value: 0x041e,
  text: "Thai"
}, {
  value: 0x041f,
  text: "Turkish"
}, {
  value: 0x0422,
  text: "Ukrainian"
}, {
  value: 0x0420,
  text: "Urdu (Pakistan)"
}, {
  value: 0x0820,
  text: "Urdu (India)"
}, {
  value: 0x0443,
  text: "Uzbek (Latin)"
}, {
  value: 0x0843,
  text: "Uzbek (Cyrillic)"
}, {
  value: 0x042a,
  text: "Vietnamese"
}];
exports.LanguageOptions = LanguageOptions;
var Constants = Object.freeze({
  DEFAULT_IAP_NETWORK_INTERFACE: 'nic0',
  DEFAULT_RDP_PORT: 3389,
  DEFAULT_LANGUAGE: Languages.EN_US,
  DEFAULT_PROTOCOL: ProtocolType.PROTOCOL_HYBRID
});
exports.Constants = Constants;
var SecurityOptions = [{
  value: ProtocolType.PROTOCOL_RDP,
  text: "RDP"
}, {
  value: ProtocolType.PROTOCOL_SSL,
  text: "TLS"
}, {
  value: ProtocolType.PROTOCOL_HYBRID,
  text: "TLS with NLA"
}];
exports.SecurityOptions = SecurityOptions;