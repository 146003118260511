import { TextDialog } from './TextDialog'

export function EulaDialog({showRef}){
    return (
        <TextDialog showRef={showRef}>
            <h1>END USER LICENSE AGREEMENT</h1>
            <p>
            IMPORTANT - READ CAREFULLY: This is a legal agreement between you (either an individual or a single legal entity), the end user, and Xtralogic, Inc., a California corporation ("Xtralogic"), covering your use of the Xtralogic RDP Client, which includes computer software  and may include associated media, printed materials, and online or electronic documentation ("Software").
            </p>
            <p>
            By using the Software you acknowledge that you have read this license agreement ("Agreement"), that you understand it, and that you agree to be bound by its terms. If you do not agree to the terms and conditions of this license agreement, do not use the Software.
            </p>

            <h2>1. OWNERSHIP.</h2>
            <p>
            The Software is protected by copyright and other intellectual property laws and treaties. Xtralogic retains the title, copyright, and other intellectual property rights in the Software. The Software is licensed for use and not sold.
            </p>

            <h2>2. GRANT OF LICENSE.</h2>
            <p>
            Upon your acceptance and subject to all of the terms and conditions of this Agreement, Xtralogic hereby grants you a non-exclusive, non-transferable license to use the Software on one electronic device (including, but not limited to, a mobile phone or personal computer) at any given time.
            </p>

            <h2>3. RESTRICTIONS ON USE AND OTHER RIGHTS</h2>
            <p>
            (a) No Reverse Engineering. You may not reverse engineer, decompile, or disassemble the Software, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation.
            </p>
            <p>
            (b) No Separation of Components. The Software is licensed as a single product. Its component parts may not be separated for use on more than one computer, nor otherwise used separately from the other parts.
            </p>
            <p>
            (c) No Derivative Works. You may not create any derivative works based on the Software or any part of it.
            </p>
            <p>
            (d) No Rental. You may not rent or lease the Software to someone else.
            </p>
            <p>
            (e) Trademarks. This Agreement does not grant you any rights in connection with any trademarks or service marks of Xtralogic. You may not remove or alter any trademark, logo, copyright or other proprietary notices, legends, symbols or labels in the Software.
            </p>

            <h2>4. DISCLAIMER OF WARRANTIES.</h2>
            <p>
            (a) THE SOFTWARE IS PROVIDED BY XTRALOGIC "AS IS". XTRALOGIC DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, FREEDOM FROM DEFECTS AND NONINFRINGEMENT OF THIRD PARTY RIGHTS.
            </p>
            <p>
            (b) Some states or jurisdictions do not allow the exclusion of implied warranties so the above limitations may not apply to you.  SHOULD ANY WARRANTIES BE FOUND TO EXIST, SUCH WARRANTIES SHALL BE LIMITED IN DURATION TO NINETY (90) DAYS FOLLOWING THE DATE YOU RECEIVE THE SOFTWARE. Some states or jurisdictions do not allow limitations on how long an implied warranty or condition lasts, so the above limitation may not apply to you.
            </p>
            <p>
            (c) This Disclaimer of Warranties constitutes an essential part of this Agreement. No use of the Software is authorized except under this disclaimer.
            </p>

            <h2>5. LIMITATION OF LIABILITY. </h2>
            <p>
            (a) IN NO EVENT WILL XTRALOGIC, ITS SUPPLIERS OR ITS RESELLERS, BE LIABLE TO YOU FOR ANY LOSS OF USE, LOSS OF INFORMATION, INTERRUPTION OF BUSINESS, OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS OR GOODWILL) REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY OR OTHERWISE, EVEN IF XTRALOGIC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
            (b) Some states or jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.
            </p>
            <p>
            (c) In any case, Xtralogic entire liability under any provision of this agreement shall not exceed the License Fee amount paid by you for the Software giving rise to the claim. If the License Fee is refunded, this agreement will terminate immediately.
            </p>

            <h2>6. EXPORT RESTRICTIONS.</h2>
            <p>
            You acknowledge that the Software is of U.S. origin and subject to U.S. export jurisdiction. You agree to comply with all applicable domestic and international laws that apply to the Software, including the U.S. Export Administration Regulations, as well as end-user, end-use, and destination restrictions issued by U.S. and foreign governments.
            </p>

            <h2>7. U.S. GOVERNMENT RESTRICTED RIGHTS NOTICE.</h2>
            <p>
            Software has been developed entirely at private expense and is a commercial computer software provided with restricted rights. Use, duplication, modification, disclosure or transfer by the U.S. Government or a U.S. Government subcontractor is subject to the restrictions set forth in the license agreement under which Software was obtained pursuant to DFARS 227.7202-3(a) or as set forth in subparagraphs (b)(1) and (2) of the Commercial Computer Software - Restricted Rights clause at FAR 52.227-19, as applicable. Manufacturer is Xtralogic, Inc., a California corporation.
            </p>

            <h2>8. TERMINATION.</h2>
            <p>
            This License Agreement is effective until it is terminated. You may terminate this License Agreement at any time. This agreement will automatically terminate if you fail to comply with any of the terms and conditions of this agreement. In any such event, you must destroy all copies of the Software and the Registration Keys in your possession or under your control. All provisions relating to confidentiality, proprietary rights, and non-disclosure shall survive the termination of this Agreement.
            </p>

            <h2>9. SEVERABILITY.</h2>
            <p>
            If any provision in this Agreement should be held illegal or unenforceable by a court having jurisdiction, such provision shall be modified to the extent necessary to render it enforceable without losing its intent or severed from this Agreement, if no such modification is possible, and other provisions of this Agreement shall remain in full force and effect.
            </p>

            <h2>10. GOVERNING LAW.</h2>
            <p>
            This Agreement shall be governed by the laws of the state of Texas, without giving effect to its conflict of law's provisions. Any legal action arising out of or relating to this Agreement will take place in the state or federal courts in the state of Texas, Travis county and nowhere else.
            </p>

            <h2>11. ENTIRE AGREEMENT.</h2>
            <p>
            This Agreement constitutes the entire agreement and understanding between you and Xtralogic and supersedes all prior or contemporaneous agreements between you and Xtralogic, whether written or oral, concerning the subject matter of this Agreement. This Agreement may only be amended in a writing signed by an authorized representative of Xtralogic. This Agreement and all of the provisions hereof shall be binding upon and inure to the benefit of you and Xtralogic and your and Xtralogic's respective successors and assigns.
            </p>
        </TextDialog>
    )
}
