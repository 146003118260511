import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Table, Container, Modal } from 'react-bootstrap';
import { BsChevronUp, BsChevronDown} from "react-icons/bs";
import { GiHamburgerMenu} from "react-icons/gi";
import { Typography } from '@material-ui/core';
import { TransportType, Server, SlidingMenu, MenuItem, MenuSeparator } from "react-rdp-client-lib";
import { Search } from './Search'
import appIconImage from '../assets/appicon48.png';
import { NoticesDialog } from './NoticesDialog'
import { EulaDialog } from './EulaDialog'

import "./Home.css"

const UiState = Object.freeze({
    NORMAL: 1,
    DELETE_DIALOG: 2,
});

const AboutDialog = ({version, showRef, showEulaDialogRef, showNoticesDialogRef}) =>{

    const [shown, setShown] = useState(false);

    showRef.current = () => setShown(true);

    return (
        <Modal
        show={shown}
        backdrop="static"
        keyboard={false}
        animation={true}>
        <Modal.Body className='modal-body'>
             <span><h2>Xtralogic RDP Client</h2></span>
             <span>Version {version}</span>
             <div><button className="link-button" as="a" onClick={() => showEulaDialogRef.current()}>End User License Agreement</button></div>
             <div><button className="link-button" as="a" onClick={() => showNoticesDialogRef.current()}>Third Party Software Notices and Licenses</button></div>
             <span>Copyright {'\u00A9'} 2022 Xtralogic, Inc. All right reserved.</span>

        </Modal.Body>
        <Modal.Footer>
           <Button variant="secondary" onClick={() => {
                 setShown(false);
           }}>
                 OK
           </Button>
        </Modal.Footer>
     </Modal>
    )
}

export function Home(props) {
    const navigate = useNavigate();

    const serverToDeleteId = useRef(null);

    const [sortAscending, setSortAscending] = props.sortAscendingState;

    const [uiState, setUiState] = useState(UiState.CONNECTING);
    const [searchInput, setSearchInput] = useState("");
    const [servers, setServers] = useState([]);
    const [filteredServers, setFilteredServers] = useState([]);

    const toggleMenuRef = React.useRef({});
    const hideMenuRef = React.useRef({});

    const showAboutDialogRef = React.useRef();
    const showEulaDialogRef = React.useRef();
    const showNoticesDialogRef = React.useRef();


    useEffect(()=>{
        props.dbService.getAllServers().then(servers => {
            setServers(servers);
        });
    },[]);

    useEffect(()=>{
        servers.sort((left, right)=>{
            //TODO = need to predicate on the username and descripiton too:  var SORT_STRING = '(description || (username ? address + username : address))';
            return sortAscending ? left.address.localeCompare(right.address) :  right.address.localeCompare(left.address)
        });

    }, [servers, sortAscending]);

    useEffect(()=>{
        setFilteredServers(servers.filter((el) => {
            if (!searchInput) {
                return true;
            }

            var searchInputLowCase = searchInput.toLowerCase();

            var searchableFields = [
                //TODO - use correct searchable fields
                'username',
                'address',
                'domain',
                'description',
                'programtostart',
                'remoteappprogram',
                'gateway_address',
                'gateway_domain',
                'gateway_username'
            ];

            for (var i = 0; i < searchableFields.length; ++i)  {
                var value = el[searchableFields[i]];
                if (!value) {
                    continue;
                }

                if (value.toLowerCase().includes(searchInputLowCase)) {
                    return true;
                }
            }

            return false;
        }));
    }, [servers, searchInput, sortAscending]);

    const connect = (index) => {
        navigate({
            pathname: '/session',
            search: "?" + new URLSearchParams({
                serverId: JSON.stringify(0),
                server: JSON.stringify(filteredServers[index]),
                transportParameters: JSON.stringify({
                    transportType: TransportType.DIRECT_SOCKET,
                }).toString()
            })
        })
    }

    const addServer = () => {
        navigate({
            pathname: '/edit',
            search: "?" + new URLSearchParams({
                server: JSON.stringify(new Server()),
            })
        })
    }

    const edit = (index) => {
        navigate({
            pathname: '/edit',
            search: "?" + new URLSearchParams({
                server: JSON.stringify(filteredServers[index]),
            })
        })
    }

    const clone = (index) => {
        let serverClone = { ...filteredServers[index] };
        delete serverClone.id;
        navigate({
            pathname: '/edit',
            search: "?" + new URLSearchParams({
                server: JSON.stringify(serverClone),
            })
        })
    }

    const deleteServer = async (id) => {
        await props.dbService.deleteServer(id);
        setServers(await props.dbService.getAllServers());
    }

   const onToggleSortAscending = () => {
      setSortAscending(!sortAscending);
   }

   return (
      <React.Fragment>
         <SlidingMenu toggleMenuRef={toggleMenuRef} hideMenuRef={hideMenuRef} showTab={false}>
            <MenuItem onClick={() => {hideMenuRef.current(); showAboutDialogRef.current();}}>About</MenuItem>
         </SlidingMenu>
         <React.Fragment>
               <Container className='home-container'>
               <Row className="my-3">
                  <Col xs={1}>
                  <img src={appIconImage} alt=""/>
                  </Col>
                  <Col xs={10}>
                     <Typography className="title-text">Xtralogic RDP Client for Web</Typography>
                  </Col>
                  <Col xs={1}>
                    <div role="button" className="hamburger-menu-button" onClick={() => toggleMenuRef.current()}>
                        <span  aria-hidden="true"><GiHamburgerMenu/></span>
                    </div>
                  </Col>
               </Row>
               <Row className="mb-3">
                  <Col xs={5}>
                     <Search searchInput={searchInput} setSearchInput={setSearchInput}/>
                  </Col>
                </Row>
               <Table striped bordered hover>
                  <thead>
                        <tr>
                           <th>Address/Description
                              <span id="sort-order">
                                    {sortAscending ?
                                       <button className="link-button" as="a" title="Sort ascending" onClick={onToggleSortAscending}><BsChevronUp/></button> :
                                       <button className="link-button" as="a" title="Sort descending" onClick={onToggleSortAscending}><BsChevronDown/></button>
                                    }
                              </span>
                        </th>
                  </tr>
                  </thead>
                  <tbody>
                        {filteredServers.map((server, index) => (
                           <tr key={index}>
                                <td>
                                    <div className="d-flex justify-content-between">
                                        <span>
                                                {server.description ?
                                                <div>
                                                    <div className="server-description">{server.description}</div>
                                                    <div className="server-address .small-server-address">{server.address}</div>
                                                </div>:
                                                <div className="server-address">{server.address}</div>
                                                }
                                        </span>
                                        <span>
                                            <Button variant='primary' className="connectio-row-button" onClick={() => connect(index)}>Connect</Button>
                                            <Button variant='info' className="connectio-row-button" onClick={() => clone(index)}>Clone</Button>
                                            <Button variant='info' className="connectio-row-button" onClick={() => edit(index)}>Edit</Button>
                                            <Button variant='danger' className="connectio-row-button" onClick={() => { serverToDeleteId.current = filteredServers[index].id;
                                                setUiState(UiState.DELETE_DIALOG); }}>Delete</Button>
                                        </span>
                                    </div>
                              </td>
                           </tr>
                        ))}
                  </tbody>
               </Table>
               <Button variant='success' onClick={addServer}>Add server</Button>
            </Container>
            <div className="footer">
                <Typography component="p" style={{ fontSize: "14px",  marginTop: "auto", color: "red", textAlign: "center"}}>The app is undergoing beta testing. Availability and feature set are subject to change without notice.</Typography>
                <Typography component="p" style={{ fontSize: "14px",  marginTop: "auto", color: "black", textAlign: "center"}}>Send feedback to support@xtralogic.com</Typography>
                <Typography component="p" style={{ fontSize: "14px",  margin: "auto", color: "black", textAlign: "center"}}>Copyright © 2022 Xtralogic, Inc. All rights reserved.</Typography>
            </div>
            <Modal
               show={uiState === UiState.DELETE_DIALOG}
               backdrop="static"
               keyboard={false}
               animation={true}>
               <Modal.Header><span><h2>Delete the server?</h2></span></Modal.Header>
               <Modal.Footer>
                  <Button variant="secondary" onClick={() => setUiState(UiState.NORMAL)}>
                        Cancel
                  </Button>
                  <Button variant="secondary" onClick={() => {
                        deleteServer(serverToDeleteId.current)
                        setUiState(UiState.NORMAL);
                  }}>
                        OK
                  </Button>
               </Modal.Footer>
            </Modal>

            <AboutDialog version={props.version} showRef={showAboutDialogRef} showEulaDialogRef={showEulaDialogRef} showNoticesDialogRef={showNoticesDialogRef}/>
            <EulaDialog showRef={showEulaDialogRef}/>
            <NoticesDialog showRef={showNoticesDialogRef}/>
         </React.Fragment>
      </React.Fragment>
   )
}