import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Table, InputGroup, Container, Tabs, Tab } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import { TransportType, Server, LanguageOptions, SecurityOptions, Constants } from "react-rdp-client-lib";
import { Formik, Field, Form, useField, yupToFormErrors, useFormikContext   } from 'formik';
import * as Yup from "yup";
import classNames from "classnames";
import _ from 'lodash';
import "./Edit.css"

const TabId = Object.freeze({
    GENERAL : 'general',
    DISPLAY : 'display',
    RESOURCES : 'resources',
    ADVANCED : 'adnanced',
});

const TextInputFormControl = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    const errorPresent = meta.touched && meta.error;

    return (
      <Fragment>
        <label className="label" htmlFor={props.id || props.name}>{label}</label>
        <input className={classNames('input', {'errorpresent': errorPresent})} {...field} {...props} />
        {errorPresent ? (
            <div className="error-message" >{meta.error}</div>
        ) : null}
      </Fragment>
    );
};

const SwitchToFieldWithError = ({
        selectTab,
        scrollBehavior = { behavior: 'smooth', block: 'center' },
    }) => {

    const { submitCount, isValid, errors } = useFormikContext()

    useEffect(() => {setTimeout(()=>{
            var element = document.querySelector('.errorpresent');
            if (element) {
                const parent_tab_pane = element.closest("div .tab-pane");
                const eventKey = parent_tab_pane.getAttribute("tab-id");
                selectTab(eventKey);
                setTimeout(()=>{
                    element.scrollIntoView(scrollBehavior);
                    element.focus();
                }, 0);
            }
        }, 0);

      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null
}

const  SelectFormControl = (props) => {
    const [field, meta, helpers] = useField(props.name);

    const onSelectionChange = (event) => {
        helpers.setValue(event.target.value);
    }

    const selectOptions = props.options.map((option, index) =>
            <option key={index} value={option.value}>{option.text}</option>
    );

    return (
        <Fragment>
            <label className="label" htmlFor={props.id || props.name}>{props.label}</label>
            <select
                id={props.id}
                name={props.name}
                value={meta.value || props.value || props.options[0].value}
                onChange={onSelectionChange}
                onBlur={field.onBlur}
            >
                {selectOptions}
            </select>
        </Fragment>
    );
}

const CheckboxFormControl = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const handleChange = (event) => {
        helpers.setValue(event.target.checked);
    }

    return (
        <label className='label' htmlFor={props.id || props.name}>
            <input onChange={handleChange}
                    id={props.id}
                    name={props.name}
                    checked={meta.value}
                    onBlur={field.onBlur}
                    type="checkbox" className='checkbox'/>
            {props.label}
        </label>
    );
};

export function Edit(props) {
    const [key, setKey] = useState('general');

    const navigate = useNavigate();
    const location = useLocation();

    const navigateHome = () => {
        navigate({
            pathname: '/',
        })
    }

    const formikRef = useRef(null);
    const postSubmitRef = useRef(navigateHome);

    const query = new URLSearchParams(location.search);
    const server = useRef(JSON.parse(query.get('server')));

    const selectTab = (eventKey) => {
        setKey(eventKey);
    }

    const connect = () => {
        postSubmitRef.current = (server) => {
            navigate({
                pathname: '/session',
                search: "?" + new URLSearchParams({
                    serverId: JSON.stringify(0),
                    server: JSON.stringify(server),
                    transportParameters: JSON.stringify({
                        transportType: TransportType.DIRECT_SOCKET,
                    }).toString()
                })
            })
        }
        formikRef.current.submitForm();
    }

    const cancel = () => {
        navigateHome();
    }

    return (
        <Container>
             <h2>Server Settings</h2>
            <Formik
                innerRef={formikRef}
                initialValues={server.current}
                validationSchema = {Yup.object({
                    address: Yup.string().required("Address is required"),
                    clientname: Yup.string().required("Client name is required"),
                })}
                onSubmit={async (values) => {
                    let server = values;

                    server.language = parseInt(server.language);
                    server.security = parseInt(server.security);

                    await props.dbService.saveServer({...server});
                    postSubmitRef.current(server);
                }}>{
                    props =>
                    <Form>
                        <SwitchToFieldWithError selectTab={selectTab}/>
                        <Tabs activeKey={key}  onSelect={(k) => setKey(k)} className="mb-3">
                            <Tab eventKey={ TabId.GENERAL } tab-id={ TabId.GENERAL } title="General">
                                <Row className="my-3">
                                    <Col xs={1}/>
                                    <Col xs={10}>
                                        <TextInputFormControl
                                            label="Address"
                                            name="address"
                                            type=""
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={1}/>
                                    <Col xs={10}>
                                        <TextInputFormControl
                                            label="Domain"
                                            name="domain"
                                            type=""
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={1}/>
                                    <Col xs={10}>
                                        <TextInputFormControl
                                            label="User name"
                                            name="username"
                                            type=""
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>

                                <Row className="my-3">
                                    <Col xs={1}/>
                                    <Col xs={10}>
                                        <TextInputFormControl
                                            label="Password"
                                            name="password"
                                            type="password"
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={1}/>
                                    <Col xs={10}>
                                        <TextInputFormControl
                                            label="Description"
                                            name="description"
                                            type=""
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={1}/>
                                    <Col xs={10}>
                                        <SelectFormControl
                                            label="Language"
                                            name="language"
                                            options={LanguageOptions}
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                        {/*<Tab eventKey={ TabId.DISPLAY } tab-id={ TabId.DISPLAY } title="Display">
                            <TextInputFormControl
                                    label="Foo"
                                    name="foo"
                                    type=""
                                    placeholder=""
                                />
                        </Tab>
                        <Tab eventKey={ TabId.RESOURCES }  tab-id={ TabId.RESOURCES } title="Resources">
                            <TextInputFormControl
                                    label="Bar"
                                    name="bar"
                                    type=""
                                    placeholder=""
                                />
                        </Tab>*/}
                        <Tab eventKey={ TabId.ADVANCED }  tab-id={ TabId.ADVANCED } title="Advanced">
                            <Row className="my-3">
                                <Col xs={1}/>
                                <Col xs={10}>
                                    <SelectFormControl
                                        label="Security"
                                        name="security"
                                        options={SecurityOptions}
                                    />
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col xs={1}/>
                                <Col xs={10}>
                                    <CheckboxFormControl
                                        label="Connect to console"
                                        name="connecttoconsole"
                                    />
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col xs={1}/>
                                <Col xs={10}>
                                    <TextInputFormControl
                                        label="Client name"
                                        name="clientname"
                                        type=""
                                        placeholder=""
                                    />
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col xs={1}/>
                                <Col xs={10}>
                                    <TextInputFormControl
                                        label="Load balanace info"
                                        name="loadbalanceinfo"
                                        type=""
                                        placeholder=""
                                    />
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                    <Row className="my-3">
                        <Col xs={1}/>
                        <Col xs={11}>
                            <Button className='button' variant="info" disabled={props.isSubmitting} onClick={( )=> connect()}>Connect</Button>
                            <Button className='button' type="submit" disabled={props.isSubmitting}>Save</Button>
                            <Button className='button' variant="info" disabled={props.isSubmitting} onClick={( )=> cancel()}>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            }
        </Formik>
    </Container>)
}

